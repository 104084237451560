import React from 'react';
import axios, { AxiosResponse } from 'axios';
import {useMutation} from 'react-query';
import {commonHeaders, Context, queryClient} from 'utils';
import { useNotifications } from 'components/notifications';
import { useIntl } from 'react-intl';
import { MessageBarType } from '@fluentui/react';

export interface ICreateAttachment {
  clientId: number;
  fundId: number;
  jobId: number;
  itemId: number;
  data: FormData;
  tableType: number;
}

function useCreateAttachment() {
    const context = React.useContext(Context);
    const { showNotification } = useNotifications();
    const { formatMessage } = useIntl();

    const { mutate: createAttachment, isLoading, isSuccess } = useMutation(({ clientId, fundId, jobId, itemId, tableType, data }: ICreateAttachment) =>
        {
            const file = data.get('file') as File;

            if(file.size === 0)
            {
                showNotification({
                    name: formatMessage({ id: 'fileSizeIsZero' }),
                    type: MessageBarType.error,
                    description: formatMessage({ id: 'fileSizeShouldBeNotZero' }, { fileName: file.name }),
                });

                return Promise.reject(formatMessage({ id: 'fileSizeShouldBeNotZero' }, { fileName: file.name }));
            }

            return axios.post(`${context?.config?.annotationsUrl}/api/1.0/clients/${clientId}/funds/${fundId}/jobs/${jobId}/items/${itemId}/attachments?tableType=${tableType}`, data, commonHeaders())
        },
        {
            onSuccess: (response) => {
                showNotification({
                    name: formatMessage({ id: 'fileUploaded' }),
                    type: MessageBarType.success,
                    description: formatMessage({ id: 'fileUploadedSuccessfully' }, { fileName: response.data.fileName }),
                });
                queryClient.invalidateQueries('getAttachments');
            }
        }
    );

    return { createAttachment, isLoading, isSuccess };
}

export { useCreateAttachment };