import {
    Callout,
    DirectionalHint, Label,
    mergeStyleSets,
    Spinner,
    Stack,
    StackItem,
    Target,
    Text, useTheme, VerticalDivider
} from '@fluentui/react';
import {FunctionComponent} from 'react';
import {useGetJobByGuid} from "../../../../hooks/useGetJobByGuid";
import {useGetJobComments} from "../../hooks";
import {useParams} from "react-router-dom";
import {useIntl} from "react-intl";
import {SanitizedText} from "../../../../components";
import {useGetOmlItems} from "./hooks/useGetOmlItems";
import {useJobContext} from "../../JobPortalLayoutPage";


interface IJobOmlPreviewProps {
    itemId: number;
    target?: Target;
    tableType: number;
    isPointerOver: boolean;
    onPointerEnter?: Function;
    onPointerLeave?: Function;
}

export const JobOmlPreview: FunctionComponent<IJobOmlPreviewProps> = ({
                                                                                  itemId,
                                                                                  target,
                                                                                  tableType, onPointerEnter, onPointerLeave,
                                                                              }) => {
    const {jobId} = useJobContext();

    const {response, isLoading} = useGetOmlItems(jobId, itemId, tableType);
    const theme = useTheme();
    
    const styles = mergeStyleSets({
        header: {
        },
        headerText: {
            fontWeight: "bold",
        },
        callout: {
            minWidth: 350,
            maxWidth: 700,
            padding: '20px 24px',
        },
        item: {
            display: 'list-item',
            marginLeft: '3%'
        }
    });

    return (
        <>
            <Callout className={styles.callout}
                     target={`#${target}`}
                     onPointerEnter={() => onPointerEnter?.()}
                     onPointerLeave={() => onPointerLeave?.()}
                     onDismiss={() => {}}
                     gapSpace={3}
                     role="alert"
                     directionalHint={DirectionalHint.rightCenter}>
                {
                    isLoading ? <Spinner/> :
                        <Stack grow tokens={{childrenGap: 16}}>
                            {(response || []).filter(x => x.isSelected).map(x => {
                                return <SanitizedText data={x.text} 
                                                      key={`oml-preview-item-${x.id.toString()}`}
                                                      theme={theme.schemes?.default}
                                                      className={styles.item}
                                                      minWidth={'80%'}
                                />
                            })}
                        </Stack>
                }
            </Callout>
        </>
    );
};